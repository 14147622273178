import React from 'react';
import './App.css';
import { API } from 'aws-amplify';
import { ThemeProvider } from '@emotion/react';
import { Container, CssBaseline, Box, Avatar, Typography, Grid, TextField, Button, Link, ImageList, ImageListItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DownloadService } from './download/DownloadService';
const logo = require('./logo.png');
const lion1 = require('./lion.jfif');
const lion2 = require('./anotherlion.jfif') ;
// https://www.pinterest.com/mgm252/manionmacdonald-favorites/
interface Props {

}
interface State {
  $: 'initial' | 'loading' | 'loaded' | 'error';
  $dl: 'initial' | 'loading' | 'loaded' | 'error';
  url: string;
  imageUrls: string[];
  zip: string;
}
export class App extends React.Component<Props, State> {
  dlS = new DownloadService();
  state: State = {
    $: 'initial',
    $dl: 'initial',
    url: '',
    imageUrls: [],
    zip: ''
  };
  form?: HTMLFormElement;
  async componentDidMount() {

  }
  get url(): string {
    if (!this.form) {
      return '';
    }
    const data = new FormData(this.form);
    return String(data.get('url') as any) || '';
  }
  async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({ imageUrls: [], $: 'loading' });
    try {
      const imageUrls = await this.getImages(this.url);
      this.setState({ imageUrls, $: 'loaded' });
    } catch (e) {
      this.setState({ imageUrls: [], $: 'error' });
    }

  }
  render() {
    return (
      <ThemeProvider theme={{}}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <img src={logo} alt="logo" className='logo-top' />
            </Avatar>
            <Typography component="h1" variant="h5">
              imgzip
            </Typography>
            <p>Download photos from a public URL (e.g. Pinterest Board or any other website!)</p>
            <Box component="form" noValidate width={"100%"} onSubmit={(e) => this.handleSubmit(e)} sx={{ mt: 3 }} ref={r => this.form = r as HTMLFormElement}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="URL (e.g. Pinterest Board)"
                    name="url"
                  />
                </Grid>
              </Grid>
              {this.renderSubmitBtn()}
            </Box>
          </Box>
          {this.renderResults()}
          <hr />
          {this.copyright()}
        </Container>
      </ThemeProvider>
    );
  }

  renderSubmitBtn() {
    const s = this.state;
    const $ = s.$;
    const loading = $ === 'loading';
    const success = $ === 'loaded';
    const error = $ === 'error';
    const green = 'rgb(76, 175, 80)';
    const red = '#f00';
    const buttonSx = {
      ...(success && {
        bgcolor: green,
        '&:hover': {
          bgcolor: green,
        },
      }),
      ...(error && {
        bgcolor: red,
        '&:hover': {
          bgcolor: red,
        },
      })
    };

    return <Box sx={{ position: 'relative' }}>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={buttonSx}
        disabled={loading}
      >Grab Images
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>;
  }

  renderDlButton() {
    const s = this.state;
    const $ = s.$dl;
    const loading = $ === 'loading';
    const success = $ === 'loaded';
    const error = $ === 'error';
    const green = 'rgb(76, 175, 80)';
    const red = '#f00';
    const buttonSx = {
      ...(success && {
        bgcolor: green,
        '&:hover': {
          bgcolor: green,
        },
      }),
      ...(error && {
        bgcolor: red,
        '&:hover': {
          bgcolor: red,
        },
      })
    };

    return <Box sx={{ position: 'relative' }}>
      <Button
        type="button"
        onClick={() => this.downloadZip()}
        variant="contained"
        fullWidth
        sx={buttonSx}
        disabled={loading}
      >Download Images
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>;
  }

  async downloadZip() {
    try {
      this.setState({ $dl: 'loading' });
      const s = this.state;
      const content = await this.dlS.downloadZip(s.imageUrls);
      const aTag = document.createElement('a');
      aTag.download = 'imgzip';
      aTag.href = content.zipUrl;
      aTag.textContent = 'imgzip.link';
      aTag.hidden = true;
      aTag.style.display = 'none';
      document.body.appendChild(aTag);
      aTag.click();
    } catch (e) {
      this.setState({ $dl: 'error' });
    }
  }

  renderResults() {
    const imageUrls = this.state.imageUrls;
    if (!imageUrls?.length) {
      return <>
        <p>Welcome to imgzip.link. Type in a public URL to find all the images on that website for you to download!</p>
        <ImageList cols={2} gap={1}>
          <ImageListItem key={lion1}><img src={lion1} alt="lion 1"/></ImageListItem>
          <ImageListItem key={lion2}><img src={lion2} alt="lion 2"/></ImageListItem>
      </ImageList>
      </>;
    }
    return <>
      <p>Found {imageUrls.length} images on {this.url}</p>
      {this.renderDlButton()}
      <ImageList variant="masonry" cols={3} gap={8}>
        {imageUrls.map((img) => (
          <ImageListItem key={img}>
            <img
              src={img}
              alt={img}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>;
  }

  copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://imgzip.link/">
          imgzip.link
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  async getImages(url: string): Promise<string[]> {
    const res = await API.post('pindlapi', '/', {
      body: {
        url
      }
    })
    return res.imageUrls || [];
  }
}
