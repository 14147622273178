import { API } from "aws-amplify";

export class DownloadService {
    async downloadZip(imageUrls: string[]): Promise<DownloadZipResponse> {
        const res = await API.post('pindlapi', '/', {
            body: {
              imageUrls
            }
          });
          return res;
    }
}
interface DownloadZipResponse {
  zipUrl: string;
}
